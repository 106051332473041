/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
    faAngleDown,
    faAngleRight,
    faArchive,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBookmark,
    faBookOpen,
    faBriefcase,
    faCar,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faClock,
    faCode,
    faColumns,
    faCouch,
    faExclamation,
    faExclamationTriangle,
    faFile,
    faFilePdf,
    faFlag,
    faGift,
    faGlobe,
    faHome,
    faLongArrowAltRight,
    faMobileAlt,
    faMotorcycle,
    faPaperclip,
    faPlay,
    faPlus,
    faPlusCircle,
    faQuestion,
    faSearch,
    faShoppingCart,
    faStar,
    faStarHalfAlt,
    faStoreAlt,
    faTable,
    faTachometerAlt,
    faUser,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
    faAngleDown,
    faAngleRight,
    faArchive,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBookmark,
    faBookOpen,
    faBriefcase,
    faCar,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faClock,
    faCode,
    faColumns,
    faCouch,
    faExclamation,
    faExclamationTriangle,
    faFile,
    faFilePdf,
    faFlag,
    faGift,
    faGlobe,
    faHome,
    faLongArrowAltRight,
    faMobileAlt,
    faMotorcycle,
    faPaperclip,
    faPlay,
    faPlus,
    faPlusCircle,
    faQuestion,
    faSearch,
    faShoppingCart,
    faStar,
    faStarHalfAlt,
    faStoreAlt,
    faTable,
    faTachometerAlt,
    faUser,
};
